/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Reve } from '../../../../../api';
import { size, map } from 'lodash';
import { Loader, Pagination, Search, Popup } from 'semantic-ui-react';
import { AsesorReveItem } from '../AsesorReveItem/AsesorReveItem';
import { useAuth } from '../../../../../hooks';

const ReveController = new Reve();

export function ListReveAsesor(props) {
  const { reload, onReload, onClose } = props;
  const [Reves, setReves] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const { user: { email } } = useAuth();
  const CorreoAsesor = email;

  const fetchReves = async (page) => {
    try {
      const response = await ReveController.getRevesByAsesor(CorreoAsesor, { page, limit: 10 });
      return response;
    } catch (error) {
      console.error(error);
      return { docs: [], limit: 0, page: 0, pages: 0, totalPages: 0 };
    }
  };

  const loadReves = async () => {
    const response = await fetchReves(page);
    setReves(response.docs);
    setPagination({
      limit: response.limit,
      page: response.page,
      pages: response.pages,
      total: response.totalPages,
    });
  };

  const loadAllReves = async () => {
    let allReves = [];
    for (let i = 1; i <= pagination.total; i++) {
      const response = await fetchReves(i);
      allReves = allReves.concat(response.docs);
    }
    return allReves;
  };


  useEffect(() => {
    loadReves();
  }, [page, reload]);

  useEffect(() => {
    if (searchTerm) {
      (async () => {
        const allReves = await loadAllReves();
        setReves(allReves);
      })();
    } else {
      loadReves();
    }
  }, [searchTerm, reload]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  const handleSearchChange = (_, { value }) => {
    setSearchTerm(value);
  };

  const filterReves = () => {
    if (!searchTerm) {
      return Reves;
    } else {
      return Reves.filter(Reve =>
        (Reve.folio && Reve.folio.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  };


  if (!Reves) return <Loader active inline="centered" />;
  if (size(Reves) === 0) return "No hay cotizaciones";

  return (
    <div className='list-cotizaciones'>
      <Popup trigger={<Search
        onSearchChange={handleSearchChange}
        value={searchTerm}
        placeholder="Buscar..."
        showNoResults={false}
      />}
        header='Busca por:'
        content='Folio de fórmula'
      />
      <br />
      {map(filterReves(), Reve => (
        <AsesorReveItem key={Reve._id} Reve={Reve} onReload={onReload} onClose={onClose} />
      ))}
      <div className='list-cotizaciones__pagination'>
        <Pagination
          totalPages={pagination.total}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
