import React from 'react'
import { Form, Container, Table, TableRow, TableBody, TableCell, TableHeader, TableHeaderCell} from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema3 } from './OpeForm.form'
import { Reve } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./OpeForm.scss";

const ReveController = new Reve();

export function OpeReveForm3(props) {
    const { onClose, onReload, Reve } = props;
    const { accessToken } = useAuth();

    const formik = useFormik({
        initialValues: initialValues(Reve),
        validationSchema: validationSchema3(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const data = {
                  codigo : formValue.codigo,
                  sOpe3: true,
                }
                if (!Reve) {
                    console.log("no deberia de pasar")
                   
                    // await equalController.createReve(accessToken, data);
                } else {
                    await ReveController.UpdateOpe(accessToken, Reve._id, data);
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });

    
    const handleSave = async () => {
        try {
            const data = {
                codigo : formik.values.codigo
            }
            console.log(formik.values.codigo);
            await ReveController.saveReveOpe(accessToken, Reve._id, data)
            onClose();
            onReload();
            } catch (error) {
            console.error(error);
        }
    }

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='cotizacion-principal_info'>
              <p>Asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesornom}</span></p>
              <p>Correo asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesor}</span></p>
              <br></br>
              {Reve.nombreoficial && <p>Nombre del producto: <span className='cotizacion-principal_info-nombre'>{Reve.nombreoficial}</span></p>}
              <p>Nombre del producto:<span className='cotizacion-principal_info-dxp'>{Reve.nombre}</span></p>
              <p>Familia del producto:<span className='cotizacion-principal_info-dxp'>{Reve.familia}</span></p>
              <p>Activos: <span className='cotizacion-principal_info-dxp'>{Reve.activos}</span></p>
              <p>Base: <span className='cotizacion-principal_info-base'>{Reve.base}</span></p>
              <p>Presentación: <span className='cotizacion-principal_info-activos'>{Reve.presentacion}</span></p>
              <p>Envase: <span className='cotizacion-principal_info-activos'>{Reve.envase}</span></p>
              <p>Funciones: <span className='cotizacion-principal_info-dxp'>{Reve.funciones}</span></p>
              <p>Área: <span className='cotizacion-principal_info-dxp'>{Reve.area}</span></p>
              <p>Fragancia: <span className='cotizacion-principal_info-dxp'>{Reve.fragancia ? 'Sí' : 'No'}</span></p>
              {(Reve.sIyD && Reve.caducidad) && <p>Caducidad: <span className='cotizacion-principal_info-nombre'>{Reve.caducidad}</span></p>}
              <br></br>
              <p>Genero del paciente: <span className='cotizacion-principal_info-base'>{Reve.genero}</span></p>
              <p>Rango de edad: <span className='cotizacion-principal_info-dxp'>{Reve.rangoedad}</span></p>
              <p>Tipo de piel: <span className='cotizacion-principal_info-dxp'>{Reve.tipopiel}</span></p>
              <br></br>
              <p>Precio Objetivo: <span className='cotizacion-principal_info-activos'>{Reve.objetivo}</span></p>
              {Reve.motivo && <p>Motivo de cancelación: <span className='cotizacion-principal_info-nombre'>{Reve.motivo}</span></p>}
              {Reve.presupuesto && <p>Presupuesto por KG de InyDe: <span className='cotizacion-principal_info-nombre'>{Reve.presupuesto}</span></p>}
              {Reve.costologrado && <p>Presupuesto por KG Logrado: <span className='cotizacion-principal_info-nombre'>{Reve.costologrado}</span></p>}
              {Reve.preciounitario && <p>Precio Unitario: <span className='cotizacion-principal_info-nombre'>{Reve.preciounitario}</span></p>}
            </Container>
            <Form.Input label="Código de Formula Reve" name="codigo" placeholder="Código de la Formula Reve" onChange={formik.handleChange} value={formik.values.codigo} error={formik.errors.codigo} />
            <br></br>
            <Form.Button type='button' primary fluid onClick={handleSave} loading={formik.isSubmitting}>
                Guardar
            </Form.Button>
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
        </Form>
    )
}
