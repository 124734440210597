export const generos = [
    { key: "Hombre", text: "Hombre", value: "Hombre" },
    { key: "Mujer", text: "Mujer", value: "Mujer" },
    { key: "Ambos", text: "Ambos", value: "Ambos" }
];

export const familia = [
    { key: "Oxidermic", text: "Oxidermic", value: "Oxidermic" },
    { key: "Sunveil", text: "Sunveil", value: "Sunveil" },
    { key: "Dibel", text: "Dibel", value: "Dibel" },
    { key: "Neudermic", text: "Neudermic", value: "Neudermic" },
    { key: "ClareSkin", text: "ClareSkin", value: "ClareSkin" },
    { key: "Capilarium", text: "Capilarium", value: "Capilarium" },
];

export const piel = [
    { key: "Normal", text: "Normal", value: "Normal" },
    { key: "Seca", text: "Seca", value: "Seca" },
    { key: "Mixta", text: "Mixta", value: "Mixta" },
    { key: "Grasa", text: "Grasa", value: "Grasa" },
    { key: "Otro", text: "Otro", value: "Otro" }
];


export const opcionesBaseFunciones = [
    { key: "Hidratante", text: "Hidratante", value: "Hidratante" },
    { key: "Emoliente", text: "Emoliente", value: "Emoliente" },
    { key: "Despigmentante", text: "Despigmentante", value: "Despigmentante" },
    { key: "Anti-edad", text: "Anti-edad", value: "Anti-edad" },
    { key: "Antioxidante", text: "Antioxidante", value: "Antioxidante" },
    { key: "Anti-Estrías", text: "Anti-Estrías", value: "Anti-Estrías" },
    { key: "Calmante", text: "Calmante", value: "Calmante" },
    { key: "Protector Solar", text: "Protector Solar", value: "Protector Solar" },
    { key: "Exfoliante", text: "Exfoliante", value: "Exfoliante" },
    { key: "Anti-acné", text: "Anti-acné", value: "Anti-acné" },
    { key: "Anti-Celulitis", text: "Anti-Celulitis", value: "Anti-Celulitis" },
];

export const opcionesBaseAreas = [
    { key: "Contorno de ojos", text: "Contorno de ojos", value: "Contorno de ojos" },
    { key: "Cara", text: "Cara", value: "Cara" },
    { key: "Manos", text: "Manos", value: "Manos" },
    { key: "Corporal", text: "Corporal", value: "Corporal" }
];

export const opcionesBaseBase = [
    { key: "Crema", text: "Crema", value: "Crema" },
    { key: "Gel", text: "Gel", value: "Gel" },
    { key: "Gel-Crema", text: "Gel-Crema", value: "Gel-Crema" },
    { key: "Suero", text: "Suero", value: "Suero" },
    { key: "Loción", text: "Loción", value: "Loción" },
    { key: "Jabón", text: "Jabón", value: "Jabón" },
    { key: "Shampoo", text: "Shampoo", value: "Shampoo" }
];

export const opcionesBaseEnvase = [
    { key: "Frasco cilíndrico", text: "Frasco cilíndrico", value: "Frasco cilíndrico" },
    { key: "Pomadera", text: "Pomadera", value: "Pomadera" },
    { key: "Airless", text: "Airless", value: "Airless" }
];
