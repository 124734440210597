import * as Yup from "yup";

export function initialValues(reve) {
    return {
        asesor: reve?.asesor || "",
        asesornom: reve?.asesornom || "",
        base: reve?.base || "",
        funciones: reve?.funciones?.split(', ') || [],
        area: reve?.area?.split(', ') || [],
        activos: reve?.activos || "",
        genero: reve?.genero || "",
        nombre: reve?.nombre || "",
        rangoedad: reve?.rangoedad || "",
        tipopiel: reve?.tipopiel || "",
        fragancia: reve?.fragancia || false,
        presentacion: reve?.presentacion || "",
        envase: reve?.envase || "",
        objetivo: reve?.objetivo || "",
        costologrado: reve?.costologrado || "",
        excel: reve?.excel || false,
        familia: reve?.familia || "",
        caducidad: reve?.caducidad || ""
    }
}

export function validationSchema() {
    return Yup.object({
        excel: Yup.boolean(true)
        .oneOf([true], "Debes activar el botón de Excel para continuar."),
        costologrado: Yup.string().required(true),
        base: Yup.string().required("El campo base es obligatorio."),
        funciones: Yup.array().min(1).required(true),
        area: Yup.array().min(1).required(true),
        activos: Yup.string().required("El campo activos es obligatorio."),
        rangoedad: Yup.string().required("El campo rango de edad es obligatorio."),
        tipopiel: Yup.string().required("El campo tipo de piel es obligatorio."),
        fragancia: Yup.boolean(),
        envase: Yup.string().required("El campo envase es obligatorio."),
        caducidad: Yup.number().required("El campo caducidad es obligatorio."),
    })
}
