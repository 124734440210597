import React, { useState }  from 'react'
import { Form, Container, Header} from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema3 } from './AsesorForm.form'
import { Reve } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./AsesorForm.scss";
import ConfirmWithReason from './ConfirmWiithReason'
const reveController = new Reve();

export function AsesorReveForm3(props) {
    const { onClose, onReload, Reve } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;
    const [showConfirm, setShowConfirm] = useState(false);

    const [confirmMessage, setConfirmMessage] = useState("");
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);
    

    const formik = useFormik({
        initialValues: initialValues(Reve, CorreoAsesor, nombreCompleto),
        validationSchema: validationSchema3(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            const data = {
                sAse2 : true,
                nombreoficial: formValue.nombreoficial,
            }
            try {
                if (Reve) {
                    await reveController.AproveReve(accessToken, Reve._id, data);
                } else {
                   console.log("Esto no deberia de pasar")
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });
    
    const handleCancel = async (reason) => {
        try {

            await reveController.CancelReve(accessToken, Reve._id, reason)
      
            onClose();
            onReload();
            onOpenCloseConfirm();
        } catch (error) {
          console.error(error)
        }
      }

      const openDesactivateActivateConfirm = () => {
        setConfirmMessage(`Cancelar Formula Reve ${Reve.folio}`);
        onOpenCloseConfirm();
      }

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='cotizacion-principal_info'>
                <p>Asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesornom}</span></p>
                <p>Correo asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesor}</span></p>
                <br></br>
                <p>Nombre del producto:<span className='cotizacion-principal_info-dxp'>{Reve.nombre}</span></p>
                <p>Familia del producto:<span className='cotizacion-principal_info-dxp'>{Reve.familia}</span></p>
                <p>Activos: <span className='cotizacion-principal_info-dxp'>{Reve.activos}</span></p>
                <p>Base: <span className='cotizacion-principal_info-base'>{Reve.base}</span></p>
                <p>Presentación: <span className='cotizacion-principal_info-activos'>{Reve.presentacion}</span></p>
                <p>Envase: <span className='cotizacion-principal_info-activos'>{Reve.envase}</span></p>
                <p>Funciones: <span className='cotizacion-principal_info-dxp'>{Reve.funciones}</span></p>
                <p>Área: <span className='cotizacion-principal_info-dxp'>{Reve.area}</span></p>
                <p>Fragancia: <span className='cotizacion-principal_info-dxp'>{Reve.fragancia ? 'Sí' : 'No'}</span></p>
                {(Reve.sIyD && Reve.caducidad) && <p>Caducidad: <span className='cotizacion-principal_info-nombre'>{Reve.caducidad}</span></p>}
                <br></br>
                <p>Genero del paciente: <span className='cotizacion-principal_info-base'>{Reve.genero}</span></p>
                <p>Rango de edad: <span className='cotizacion-principal_info-dxp'>{Reve.rangoedad}</span></p>
                <p>Tipo de piel: <span className='cotizacion-principal_info-dxp'>{Reve.tipopiel}</span></p>
                <br></br>
                <p>Precio Objetivo: <span className='cotizacion-principal_info-activos'>{Reve.objetivo}</span></p>
                {(Reve.sOpe2 && Reve.preciounitario) && <p>Precio Unitario: <span className='cotizacion-principal_info-nombre'>{Reve.preciounitario}</span></p>}
                <Form.Input label="Nombre del producto oficial" name="nombreoficial" placeholder="Nombre del producto oficial" onChange={formik.handleChange} value={formik.values.nombreoficial} error={formik.errors.nombreoficial} />
            </Container>
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
            <Form.Button type='button' color='red' fluid onClick={openDesactivateActivateConfirm}>
                Cancelar
            </Form.Button>
            <ConfirmWithReason open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={handleCancel} content={confirmMessage} size='mini'></ConfirmWithReason>
        </Form>
    )
}
