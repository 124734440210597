import React from 'react'
import { Form, Container, Table, TableRow, TableBody, TableCell, TableHeader, TableHeaderCell} from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema2 } from './OpeForm.form'
import { Reve } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./OpeForm.scss";

const ReveController = new Reve();

export function OpeReveForm2(props) {
    const { onClose, onReload, Reve } = props;
    const { accessToken } = useAuth();

    const formik = useFormik({
        initialValues: initialValues(Reve),
        validationSchema: validationSchema2(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            try {
                const data = {
                  preciounitario : formValue.preciounitario,
                  presentacion : Reve.presentacion,
                }
                if (!Reve) {
                    console.log("no deberia de pasar")
                   
                    // await equalController.createReve(accessToken, data);
                } else {
                    await ReveController.UpdateOpe(accessToken, Reve._id, data);
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });

    
    const handleSave = async () => {
        try {
            const data = {
                preciounitario : formik.values.preciounitario
            }
            console.log(formik.values.preciounitario);
            await ReveController.saveReveOpe(accessToken, Reve._id, data)
            onClose();
            onReload();
            } catch (error) {
            console.error(error);
        }
    }
    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='cotizacion-principal_info'>
              <p>Asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesornom}</span></p>
              <p>Correo asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesor}</span></p>
              <br></br>
              <p>Nombre del producto:<span className='cotizacion-principal_info-dxp'>{Reve.nombre}</span></p>
              <p>Familia del producto:<span className='cotizacion-principal_info-dxp'>{Reve.familia}</span></p>
              <p>Activos: <span className='cotizacion-principal_info-dxp'>{Reve.activos}</span></p>
              <p>Base: <span className='cotizacion-principal_info-base'>{Reve.base}</span></p>
              <p>Presentación: <span className='cotizacion-principal_info-activos'>{Reve.presentacion}</span></p>
              <p>Envase: <span className='cotizacion-principal_info-activos'>{Reve.envase}</span></p>
              <p>Funciones: <span className='cotizacion-principal_info-dxp'>{Reve.funciones}</span></p>
              <p>Área: <span className='cotizacion-principal_info-dxp'>{Reve.area}</span></p>
              <p>Fragancia: <span className='cotizacion-principal_info-dxp'>{Reve.fragancia ? 'Sí' : 'No'}</span></p>
              {(Reve.sIyD && Reve.caducidad) && <p>Caducidad: <span className='cotizacion-principal_info-nombre'>{Reve.caducidad}</span></p>}
              <br></br>
              <p>Genero del paciente: <span className='cotizacion-principal_info-base'>{Reve.genero}</span></p>
              <p>Rango de edad: <span className='cotizacion-principal_info-dxp'>{Reve.rangoedad}</span></p>
              <p>Tipo de piel: <span className='cotizacion-principal_info-dxp'>{Reve.tipopiel}</span></p>
              <br></br>
              <p>Precio Objetivo: <span className='cotizacion-principal_info-activos'>{Reve.objetivo}</span></p>
              {Reve.motivo && <p>Motivo de cancelación: <span className='cotizacion-principal_info-nombre'>{Reve.motivo}</span></p>}
              {Reve.presupuesto && <p>Presupuesto de InyDe: <span className='cotizacion-principal_info-nombre'>{Reve.presupuesto}</span></p>}
            </Container>
        <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell>Presupuesto</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                        <TableCell>KG</TableCell>
                        <TableCell>{Reve.presupuesto}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Container>
            <br></br>
            {Reve.sIyD && (
              <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        <TableHeaderCell>Presupuesto Logrado</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                        <TableCell>KG</TableCell>
                        <TableCell>{Reve.costologrado}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Container>
          )}
            <br></br>
            <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        <TableHeaderCell>Presentacion</TableHeaderCell>
                        <TableHeaderCell>Precio Unitario</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                        <TableCell>{Reve.presentacion} ml</TableCell>
                        <TableCell><Form.Input name='preciounitario' placeholder='0' onChange={(e) => {formik.setFieldValue('preciounitario', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.preciounitario} error={formik.errors.preciounitario} /></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Container>
            <br></br>
            <Form.Button type='button' primary fluid onClick={handleSave} loading={formik.isSubmitting}>
                Guardar
            </Form.Button>
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                Aceptar
            </Form.Button>
        </Form>
    )
}
