import { ENV } from "../utils";

export class Reve {
    baseApi = ENV.BASE_API;
    
    
    async createReve(accessToken, ReveData) {
      try {
        console.log("entro a la peticion");
          const data = ReveData;
        //   console.log(data);
          const url = `${this.baseApi}/${ENV.API_ROUTES.REVE}`
          const params = {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(data),
          }
          const response = await fetch(url, params)
          const result = await response.json()
          if (response.status !== 200) throw result;
          return result;
      } catch (error) {
          throw error;
      }
  }

    async getRevesByAsesor(asesor, params) {
        try {
            const pageFilter = `page=${params?.page || 1}`;
            const limitFilter = `limit=${params?.limit || 10}`;
            const email = `asesor=${asesor || ""}`;
            const url = `${this.baseApi}/${ENV.API_ROUTES.REVES}?${email}&${pageFilter}&${limitFilter}`;
            const response = await fetch(url);
            const result = await response.json();
            if (response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error
        }
    }

    async getReves(params) {
        try {
            const pageFilter = `page=${params?.page || 1}`;
            const limitFilter = `limit=${params?.limit || 10}`;
            const url = `${this.baseApi}/${ENV.API_ROUTES.REVES}?${pageFilter}&${limitFilter}`;
            const response = await fetch(url);
            const result = await response.json();
            if (response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error
        }
    }

    async UpdateOpe(accessToken, id, bioequalData) {
        try {
            console.log("entre al ope")
            const data = bioequalData;
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            })
            const url = `${this.baseApi}/${ENV.API_ROUTES.OPE}/${ENV.API_ROUTES.REVE}/${id}`
            const params = {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async updateTime(accessToken, id, reveData) {
        try {
            console.log("entre al ope")
            const data = reveData;
            const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.REVE}/${id}`
            const params = {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async saveReveOpe(accessToken, id, bioequalData) {
    try {
        const data = bioequalData;
        console.log(bioequalData)
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            })
        const url = `${this.baseApi}/${ENV.API_ROUTES.SAVEOPE}/${ENV.API_ROUTES.REVE}/${id}`
        const params = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();
        if (response.status !== 200) throw result;
        return result;
    } catch (error) {
        throw error;
    }
    }

    async ReveInyde(accessToken, id, reveData) {
        try {
            const data = reveData;
    
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
            })
            const url = `${this.baseApi}/${ENV.API_ROUTES.INYDE}/${ENV.API_ROUTES.REVE}/${id}`
            const params = {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 200) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async CancelReve(accessToken, id, reason) {
        const data = {
            cancelmotivo: reason
        }
        console.log("esta data es: " + reason)
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.REVE}/${id}`
            const params = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 201) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async CancelReveInyde(accessToken, id, reason) {
        const data = {
            cancelmotivo: reason
        }
        console.log("esta data es: " + reason)
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.INYDE}/${ENV.API_ROUTES.REVE}/${id}`
            const params = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 201) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async RegresarReve(accessToken, id, reason) {
        const data = {
            backmotivo: reason
        }
        console.log("esta data es: " + reason)
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.REVE}/${id}`
            const params = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 201) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

    async AproveReve(accessToken, id, data){
        try {
            const url = `${this.baseApi}/${ENV.API_ROUTES.APROVE}/${ENV.API_ROUTES.USER}/${ENV.API_ROUTES.REVE}/${id}`
            const params = {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            };
            const response = await fetch(url, params);
            const result = await response.json();
            if (response.status !== 201) throw result;
            return result;
        } catch (error) {
            throw error;
        }
    }

}