import * as Yup from "yup";

export function initialValues(reve, email, nombreCompleto) {
    return {
        asesor: reve && reve.asesor ? reve.asesor : email,
        asesornom: reve && reve.asesornom ? reve.asesornom : nombreCompleto,
        base: reve?.base || "",
        activos: reve?.activos || "",
        funciones: reve?.funciones || [],
        area: reve?.area || [],
        genero: reve?.genero || "",
        nombre: reve?.nombre || "",
        rangoedad: reve?.rangoedad || "",
        tipopiel: reve?.tipopiel || "",
        fragancia: reve?.fragancia || false,
        presentacion: reve?.presentacion || "",
        envase: reve?.envase || "",
        costoobjetivo: reve?.costoobjetivo || "",
        fechadeseada: reve?.fechadeseada || ""
    };
}

export function validationSchema() {
    return Yup.object({
        asesor: Yup.string().required(true),
        asesornom: Yup.string().required(true),
        base: Yup.string().required(true),
        funciones: Yup.array()
        .of(Yup.string().required("Cada función debe ser una cadena de texto"))
        .min(1, "Debes proporcionar al menos una función")
        .required("El campo funciones es obligatorio"),
        area: Yup.array()
        .of(Yup.string().required("Cada área debe ser una cadena de texto"))
        .min(1, "Debes proporcionar al menos una área")
        .required("El campo área es obligatorio"),
        activos: Yup.string().required(true),
        genero: Yup.string().required(true),
        nombre: Yup.string().required(true),
        rangoedad: Yup.string().required(true),
        tipopiel: Yup.string().required(true),
        presentacion: Yup.string().required(true),
        envase: Yup.string().required(true),
        objetivo: Yup.number().required(true),
        familia: Yup.string().required(true),
        fragancia: Yup.boolean(),
        fechadeseada: Yup.string()
        .required('La fecha es obligatoria')
        .test('formato-fecha', 'La fecha debe tener el formato dd/mm/yyyy', (value) => {
          // Expresión regular para validar el formato dd/mm/yyyy
          const regex = /^\d{2}\/\d{2}\/\d{4}$/;
          if (!regex.test(value)) {
            return false; // No cumple con el formato
          }
    
          // Verificar si la fecha es válida
          const [day, month, year] = value.split('/');
          const inputDate = new Date(`${year}-${month}-${day}T00:00:00`); // Fecha ingresada
          const currentDate = new Date(); // Fecha actual
    
          // Verificar que el día, mes y año coincidan con los valores ingresados
          const isValidDate =
            inputDate.getFullYear() === Number(year) &&
            inputDate.getMonth() + 1 === Number(month) &&
            inputDate.getDate() === Number(day);
    
          if (!isValidDate) {
            return false; // Fecha inválida
          }
    
          // Verificar que la fecha no sea anterior a la fecha actual
          return inputDate >= currentDate;
        })
        .test('fecha-futura', 'La fecha no puede ser anterior a la fecha actual', (value) => {
          const [day, month, year] = value.split('/');
          const inputDate = new Date(`${year}-${month}-${day}T00:00:00`); // Fecha ingresada
          const currentDate = new Date(); // Fecha actual
    
          return inputDate >= currentDate;
        }),
    })
}

export function validationSchema2() {
    return Yup.object({
    })
}

export function validationSchema3() {
    return Yup.object({
        nombreoficial: Yup.string().required(true),
    })
}


export function validationSchemaTime() {
    return Yup.object({
      newfecha: Yup.string()
        .required('La fecha es obligatoria')
        .test('formato-fecha', 'La fecha debe tener el formato dd/mm/yyyy', (value) => {
          // Expresión regular para validar el formato dd/mm/yyyy
          const regex = /^\d{2}\/\d{2}\/\d{4}$/;
          if (!regex.test(value)) {
            return false; // No cumple con el formato
          }
    
          // Verificar si la fecha es válida
          const [day, month, year] = value.split('/');
          const inputDate = new Date(`${year}-${month}-${day}T00:00:00`); // Fecha ingresada
          const currentDate = new Date(); // Fecha actual
    
          // Verificar que el día, mes y año coincidan con los valores ingresados
          const isValidDate =
            inputDate.getFullYear() === Number(year) &&
            inputDate.getMonth() + 1 === Number(month) &&
            inputDate.getDate() === Number(day);
    
          if (!isValidDate) {
            return false; // Fecha inválida
          }
    
          // Verificar que la fecha no sea anterior a la fecha actual
          return inputDate >= currentDate;
        })
        .test('fecha-futura', 'La fecha no puede ser anterior a la fecha actual', (value) => {
          const [day, month, year] = value.split('/');
          const inputDate = new Date(`${year}-${month}-${day}T00:00:00`); // Fecha ingresada
          const currentDate = new Date(); // Fecha actual
    
          return inputDate >= currentDate;
        }),
    })
}