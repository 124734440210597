import React, { useState } from 'react'
import { Button, Icon, Label, Confirm } from 'semantic-ui-react';
import { BasicModal } from '../../../../Shared';
import "./AsesorItem.scss";
import { Reve } from '../../../../../api';
import { useAuth } from '../../../../../hooks'
import { AsesorView, AsesorReveForm, AsesorReveForm2, AsesorReveForm3, AsesorReveFormTime} from '../AsesorReveForm';
const ReveController = new Reve();

export function AsesorReveItem(props) {
  const { Reve, onReload } = props;
  const { accessToken } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const date = new Date(Reve.created_at);
  const createdate = date.toLocaleDateString();
  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseModal2 = () => setShowModal2((prevState) => !prevState);
  const onOpenCloseModal3 = () => setShowModal3((prevState) => !prevState);

  const openVerDoc = () => {
    setTitleModal(`Folio de Reve: ${Reve.folio}`);
    onOpenCloseModal();
  }

  const openVerTime = () => {
    setTitleModal(`Folio de Reve: ${Reve.folio}`);
    onOpenCloseModal3();
  }
  
  const openFormReve = () => {
    setTitleModal(`Folio de Cotización Reve: ${Reve.folio}`);
    onOpenCloseModal2();
  }



  let contentView = <AsesorView onClose={onOpenCloseModal} onReload={onReload} Reve={Reve} />;
  let contentFormTime = <AsesorReveFormTime onClose={onOpenCloseModal3} onReload={onReload} Reve={Reve} />;
  let contentForm;
  if(!Reve.sOpe){
     contentForm = <AsesorReveForm onClose={onOpenCloseModal2} onReload={onReload} Reve={Reve} />;
  }
  else if(Reve.sIyD && !Reve.sOpe2){
     contentForm = <AsesorReveForm2 onClose={onOpenCloseModal2} onReload={onReload} Reve={Reve} />;
  }
  else if(Reve.sOpe2 && !Reve.sAse2){
    contentForm = <AsesorReveForm3 onClose={onOpenCloseModal2} onReload={onReload} Reve={Reve} />;
 }

  let getStatusGeneralColor;
  if (Reve.StatusGeneral === 'Pendiente') {
    getStatusGeneralColor = 'orange'
  } else if (Reve.StatusGeneral === 'Cancelado') {
    getStatusGeneralColor = 'red';
  } else if (Reve.StatusGeneral === 'Finalizado') {
    getStatusGeneralColor = 'green'
  }

  
  return (
    <>
      <div className='cotizacion-item'>
         <div className='column'>
          <p className='cotizacion-item__info'>
            <span className='cotizacion-item__info-label'>Folio fórmula:</span>
            <span className='cotizacion-item__info-valor'>{Reve.folio}</span><br />
            <span className='cotizacion-item__info-label'>Fecha deseada:</span>
            <span className='cotizacion-item__info-valor'> {Reve.fechadeseada?.at(-1) || 'No disponible'}</span>
            <span className='cotizacion-item__info-label'>Creado:</span>
            <span className='cotizacion-item__info-valor'>{createdate}</span><br/>
          </p>
        </div>
        <div className='column'>
          <p className='cotizacion-item__info'>
            {Reve.nombreoficial && (
              <>
                <span className='cotizacion-item__info-label'>Nombre oficial del producto:</span>
                <span className='cotizacion-item__info-valor'>{Reve.nombreoficial}</span>
              </>
            )}
            <span className='cotizacion-item__info-label'>Nombre del producto:</span>
            <span className='cotizacion-item__info-valor'>{Reve.nombre}</span>
            <span className='cotizacion-item__info-label'>Activos:</span>
            <span className='cotizacion-item__info-valor'>{Reve.activos}</span>
          </p>
        </div>
            
        <div className='column'>
                    <p className='cotizacion-item__info'>
                      <label className='estatus_gn'>Estatus General: <Label className={`cotizacion-item__info-statusinde`} color={getStatusGeneralColor}>
                        {Reve.StatusGeneral}
                      </Label></label>
                      <label className='estatus_gn'>Estatus Operaciones: <Label className={`cotizacion-item__info-statusinde`} color={Reve.StatusGeneral === "Cancelado" ? "red" : (Reve.sOpe ? 'green' : 'orange')}>
                        { Reve.StatusGeneral === "Cancelado" ? "Cancelado" : (Reve.sOpe ? 'Finalizado' : 'Pendiente')}
                      </Label></label>
                      <label className='estatus_gn'>Estatus Investigación y Desarrollo: <Label className={`cotizacion-item__info-statusinde`} color={Reve.StatusGeneral === "Cancelado" ? "red" : (Reve.sIyD ? 'green' : 'orange')}>
                        { Reve.StatusGeneral === "Cancelado" ? "Cancelado" : (Reve.sIyD ? 'Finalizado' : 'Pendiente')}
                      </Label></label>
                      <label className='estatus_gn'>Estatus Confirmacion de alta asesor: <Label className={`cotizacion-item__info-statusinde`} color={Reve.StatusGeneral === "Cancelado" ? "red" : (Reve.sAse ? 'green' : 'orange')}>
                        { Reve.StatusGeneral === "Cancelado" ? "Cancelado" : (Reve.sAse ? 'Finalizado' : 'Pendiente')}
                      </Label></label>
                      <label className='estatus_gn'>Estatus Operación Final: <Label className={`cotizacion-item__info-statusinde`} color={Reve.StatusGeneral === "Cancelado" ? "red" : (Reve.sOpe2 ? 'green' : 'orange')}>
                        { Reve.StatusGeneral === "Cancelado" ? "Cancelado" : (Reve.sOpe2 ? 'Finalizado' : 'Pendiente')}
                      </Label></label>
                      <label className='estatus_gn'>Estatus Javier Confirmacion Final: <Label className={`cotizacion-item__info-statusinde`} color={Reve.StatusGeneral === "Cancelado" ? "red" : (Reve.sAse2 ? 'green' : 'orange')}>
                        { Reve.StatusGeneral === "Cancelado" ? "Cancelado" : (Reve.sAse2 ? 'Finalizado' : 'Pendiente')}
                      </Label></label>
                      <label className='estatus_gn'>Estatus Operacion codigo Final: <Label className={`cotizacion-item__info-statusinde`} color={Reve.StatusGeneral === "Cancelado" ? "red" : (Reve.sOpe3 ? 'green' : 'orange')}>
                        { Reve.StatusGeneral === "Cancelado" ? "Cancelado" : (Reve.sOpe3 ? 'Finalizado' : 'Pendiente')}
                      </Label></label>
                    </p>
        </div>
        <div style={{ display: 'flex', gap: '0' }}>
            {Reve.StatusGeneral !== 'Cancelado' && (
              ((Reve.sIyD && !Reve.sAse) || (Reve.sOpe2 && !Reve.sAse2)) && (
            <Button icon primary onClick={openFormReve}>
              <Icon name='edit'/>
            </Button>
              )
              )}
            <Button icon primary onClick={openVerDoc}>
              <Icon name='eye' />
            </Button>
            <Button icon primary onClick={openVerTime}>
            <Icon name='clock' />
            </Button>
            
        </div>
      </div>
      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        {contentView}
      </BasicModal>
      <BasicModal show={showModal2} close={onOpenCloseModal2} title={titleModal}>
        {contentForm}
      </BasicModal>
      <BasicModal show={showModal3} close={onOpenCloseModal3} title={titleModal}>
        {contentFormTime}
      </BasicModal>
    </>
  )
}
