import React, { useState }  from 'react'
import { Form, Container, Header} from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchemaTime } from './AsesorForm.form'
import { Reve } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./AsesorForm.scss";
import ConfirmWithReason from './ConfirmWiithReason'
const reveController = new Reve();

const DateInput = ({ label, name, placeholder, onChange, value, error }) => {
    const handleChange = (e) => {
      const input = e.target.value.replace(/\D/g, ''); // Solo números
      let formattedDate = '';
  
      if (input.length > 0) {
        formattedDate = input.slice(0, 2);
        if (input.length > 2) {
          formattedDate += '/' + input.slice(2, 4);
          if (input.length > 4) {
            formattedDate += '/' + input.slice(4, 8);
          }
        }
      }
  
      e.target.value = formattedDate;
      onChange(e);
    };
  
    return (
      <Form.Input
        label={label}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        error={error}
      />
    );
  };

export function AsesorReveFormTime(props) {
    const { onClose, onReload, Reve } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;

    const formik = useFormik({
        initialValues: initialValues(Reve, CorreoAsesor, nombreCompleto),
        validationSchema: validationSchemaTime(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            const data = {
                newfecha: formValue.newfecha,
            }
            try {
                if (Reve) {
                    await reveController.updateTime(accessToken, Reve._id ,data);
                } else {
                   console.log("Esto no deberia de pasar")
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });
    

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <h3>Historial de Fechas Deseadas</h3>
                        {Reve?.fechadeseada?.length > 0 ? (
                            <ul>
                                {Reve.fechadeseada.map((fecha, index) => (
                                    <li key={index}>{fecha}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No hay fechas registradas.</p>
                        )}
                {(Reve.StatusGeneral !== 'Finalizado' && Reve.StatusGeneral !== 'Cancelado' ) && (
                <>
                <DateInput
                        label="Fecha deseada"
                        name="newfecha"
                        placeholder="dd/mm/yyyy"
                        onChange={formik.handleChange}
                        value={formik.values.newfecha}
                        error={formik.errors.newfecha}/>
                <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
                            Actualizar tiempo deseado
                </Form.Button>
                </>
            )}
        </Form>
    )
}
