import React, {useState} from 'react'
import { Form, Container, Header} from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './AsesorForm.form'
import { Reve } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import "./AsesorForm.scss";

const reveController = new Reve();

const generos = [
    { key: "Hombre", text: "Hombre", value: "Hombre" },
    { key: "Mujer", text: "Mujer", value: "Mujer" },
    { key: "Ambos", text: "Ambos", value: "Ambos" }
];

const familia = [
    { key: "Oxidermic", text: "Oxidermic", value: "Oxidermic" },
    { key: "Sunveil", text: "Sunveil", value: "Sunveil" },
    { key: "Dibel", text: "Dibel", value: "Dibel" },
    { key: "Neudermic", text: "Neudermic", value: "Neudermic" },
    { key: "ClareSkin", text: "ClareSkin", value: "ClareSkin" },
    { key: "Capilarium", text: "Capilarium", value: "Capilarium" },
];

const piel = [
    { key: "Normal", text: "Normal", value: "Normal" },
    { key: "Seca", text: "Seca", value: "Seca" },
    { key: "Mixta", text: "Mixta", value: "Mixta" },
    { key: "Grasa", text: "Grasa", value: "Grasa" },
    { key: "Otro", text: "Otro", value: "Otro" }
];

const DateInput = ({ label, name, placeholder, onChange, value, error }) => {
    const handleChange = (e) => {
      const input = e.target.value.replace(/\D/g, ''); // Solo números
      let formattedDate = '';
  
      if (input.length > 0) {
        formattedDate = input.slice(0, 2);
        if (input.length > 2) {
          formattedDate += '/' + input.slice(2, 4);
          if (input.length > 4) {
            formattedDate += '/' + input.slice(4, 8);
          }
        }
      }
  
      e.target.value = formattedDate;
      onChange(e);
    };
  
    return (
      <Form.Input
        label={label}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        error={error}
      />
    );
  };

export function AsesorReveForm(props) {
    const { onClose, onReload, reve } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const CorreoAsesor = email;
    const nombreCompleto = `${firstname} ${lastname}`;
    console.log(reve);
    const [funciones, setFunciones] = useState([
        { key: "Hidratante", text: "Hidratante", value: "Hidratante" },
        { key: "Emoliente", text: "Emoliente", value: "Emoliente" },
        { key: "Despigmentante", text: "Despigmentante", value: "Despigmentante" },
        { key: "Anti-edad", text: "Anti-edad", value: "Anti-edad" },
        { key: "Antioxidante", text: "Antioxidante", value: "Antioxidante" },
        { key: "Anti-Estrías", text: "Anti-Estrías", value: "Anti-Estrías" },
        { key: "Calmante", text: "Calmante", value: "Calmante" },
        { key: "Protector Solar", text: "Protector Solar", value: "Protector Solar" },
        { key: "Exfoliante", text: "Exfoliante", value: "Exfoliante" },
        { key: "Anti-acné", text: "Anti-acné", value: "Anti-acné" },
        { key: "Anti-Celulitis", text: "Anti-Celulitis", value: "Anti-Celulitis" }
    ]);

    const [area, setArea] = useState([
        { key: "Contorno de ojos", text: "Contorno de ojos", value: "Contorno de ojos" },
        { key: "Cara", text: "Cara", value: "Cara" },
        { key: "Manos", text: "Manos", value: "Manos" },
        { key: "Corporal", text: "Corporal", value: "Corporal" }
    ]);

    const [base, setBase] = useState([
        { key: "Crema", text: "Crema", value: "Crema" },
        { key: "Gel", text: "Gel", value: "Gel" },
        { key: "Gel-Crema", text: "Gel-Crema", value: "Gel-Crema" },
        { key: "Suero", text: "Suero", value: "Suero" },
        { key: "Loción", text: "Loción", value: "Loción" },
        { key: "Jabón", text: "Jabón", value: "Jabón" },
        { key: "Shampoo", text: "Shampoo", value: "Shampoo" }
    ]);

    const [envase, setEnvase] = useState([
        { key: "Frasco cilíndrico", text: "Frasco cilíndrico", value: "Frasco cilíndrico" },
        { key: "Pomadera", text: "Pomadera", value: "Pomadera" },
        { key: "Airless", text: "Airless", value: "Airless" }
    ]);

    const formik = useFormik({
        initialValues: initialValues(reve, CorreoAsesor, nombreCompleto),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            console.log("entro al submit");
            try {
                console.log("entro al try");
                const funcionesSeleccionados = formValue.funciones;
                const funcionesTexto = funcionesSeleccionados.join(', ');
                const areaSeleccionados = formValue.area;
                const areaTexto = areaSeleccionados.join(', ');
                const data = {
                    asesor: CorreoAsesor,
                    asesornom: nombreCompleto,
                    base: formValue.base,
                    funciones: funcionesTexto ? funcionesTexto : [],
                    area: areaTexto ? areaTexto : [],
                    activos: formValue.activos,
                    genero: formValue.genero,
                    nombre: formValue.nombre,
                    rangoedad: formValue.rangoedad,
                    tipopiel: formValue.tipopiel,
                    fragancia: formValue.fragancia ? formValue.fragancia : false,
                    presentacion: formValue.presentacion,
                    envase: formValue.envase,
                    objetivo: formValue.objetivo,
                    familia: formValue.familia,
                    fechadeseada: formValue.fechadeseada
                };
                
                if (!reve) {
                    await reveController.createReve(accessToken, data);
                } else {
                   console.log("Esto no deberia de pasar")
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });
    
    const fechaActual = new Date().toLocaleDateString('es-ES');

    const handleAddCustomOption = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otro?.trim();
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!funciones.find((item) => item.value === newOption)) {
                    const updatedFunciones = [...funciones, { key: newOption, text: newOption, value: newOption }];
                    setFunciones(updatedFunciones);
                }
                // Asegurar que `formik.values.funciones` es un array válido.
                const currentFunciones = formik.values.funciones || [];
                formik.setFieldValue("funciones", [...currentFunciones, newOption]);
                formik.setFieldValue("otro", ""); // Resetear el input.
            }
        }
    };
    const handleAddCustomOptionarea = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otroarea?.trim();
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!area.find((item) => item.value === newOption)) {
                    const updatedAreas = [...area, { key: newOption, text: newOption, value: newOption }];
                    setArea(updatedAreas);
                }
                // Asegurar que `formik.values.funciones` es un array válido.
                const currentAreas = formik.values.area || [];
                formik.setFieldValue("area", [...currentAreas, newOption]);
                formik.setFieldValue("otroarea", ""); // Resetear el input.
            }
        }
    };
    const handleAddCustomOptionbase = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otrobase?.trim(); // Validar si tiene valor antes de trim().
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!base.find((item) => item.value === newOption)) {
                    const updatedBases = [...base, { key: newOption, text: newOption, value: newOption }];
                    setBase(updatedBases); // Actualizar las opciones del dropdown.
                }
                // Establecer el nuevo valor como el valor seleccionado del dropdown.
                formik.setFieldValue("base", newOption);
                formik.setFieldValue("otrobase", ""); // Resetear el input.
            }
        }
    };
    const handleAddCustomOptionenvase = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otroenvase?.trim(); // Validar si tiene valor antes de trim().
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!envase.find((item) => item.value === newOption)) {
                    const updatedEnvase = [...envase, { key: newOption, text: newOption, value: newOption }];
                    setEnvase(updatedEnvase); // Actualizar las opciones del dropdown.
                }
                // Establecer el nuevo valor como el valor seleccionado del dropdown.
                formik.setFieldValue("envase", newOption);
                formik.setFieldValue("otroenvase", ""); // Resetear el input.
            }
        }
    };
    
    const onBlur = (e) => {
        const newOption = formik.values.otro?.trim();
        if (newOption) {
            if (!funciones.find((item) => item.value === newOption)) {
                const updatedFunciones = [...funciones, { key: newOption, text: newOption, value: newOption }];
                setFunciones(updatedFunciones);
            }
            const currentFunciones = formik.values.funciones || [];
            formik.setFieldValue("funciones", [...currentFunciones, newOption]);
            formik.setFieldValue("otro", ""); // Resetear el input.
        }
    }
    const onBlurarea = (e) => {
        const newOption = formik.values.otroarea?.trim();
        if (newOption) {
            if (!area.find((item) => item.value === newOption)) {
                const updatedAreas = [...area, { key: newOption, text: newOption, value: newOption }];
                setArea(updatedAreas);
            }
            const currentAreas = formik.values.areas || [];
            formik.setFieldValue("areas", [...currentAreas, newOption]);
            formik.setFieldValue("otroarea", ""); // Resetear el input.
        }
    }
    const onBlurbase = (e) => {
        const newOption = formik.values.otrobase?.trim(); // Validar si tiene valor antes de trim().
        if (newOption) {
            // Verificar si la opción ya existe en las opciones.
            if (!base.find((item) => item.value === newOption)) {
                const updatedBases = [...base, { key: newOption, text: newOption, value: newOption }];
                setBase(updatedBases); // Actualizar las opciones del dropdown.
            }
            // Establecer el nuevo valor como el valor seleccionado del dropdown.
            formik.setFieldValue("base", newOption);
            formik.setFieldValue("otrobase", ""); // Resetear el input.
        }
    }

    const onBlurenvase = (e) => {
        const newOption = formik.values.otroenvase?.trim(); // Validar si tiene valor antes de trim().
        if (newOption) {
            // Verificar si la opción ya existe en las opciones.
            if (!envase.find((item) => item.value === newOption)) {
                const updatedEnvases = [...envase, { key: newOption, text: newOption, value: newOption }];
                setEnvase(updatedEnvases); // Actualizar las opciones del dropdown.
            }
            // Establecer el nuevo valor como el valor seleccionado del dropdown.
            formik.setFieldValue("envase", newOption);
            formik.setFieldValue("otroenvase", ""); // Resetear el input.
        }
    }
 
    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='form-cotizacion__primer'>
                <Form.Group className="form-group">
                    <p>Asesor: <span>{nombreCompleto}</span></p>
                    <p>Fecha: <span>{fechaActual}</span></p>
                </Form.Group>
                <DateInput
                    label="Fecha deseada"
                    name="fechadeseada"
                    placeholder="dd/mm/yyyy"
                    onChange={formik.handleChange}
                    value={formik.values.fechadeseada}
                    error={formik.errors.fechadeseada}
                />
                <Form.Input label="Nombre del producto" name="nombre" placeholder="Nombre del producto" onChange={formik.handleChange} value={formik.values.nombre} error={formik.errors.nombre} />
                <Form.Dropdown label="Familia" placeholder="Seleccione una familia" name="familia" options={familia} selection onChange={(_, data) => formik.setFieldValue("familia", data.value)} value={formik.values.familia} error={formik.errors.familia} />
                <Header className='center'>Características del producto</Header>
                <Form.Dropdown label="Género" placeholder="Seleccione un genero o ambos" name="genero" options={generos} selection onChange={(_, data) => formik.setFieldValue("genero", data.value)} value={formik.values.genero} error={formik.errors.genero} />
                <Form.Input label="Rango de edad" name="rangoedad" placeholder="Rango de edad" onChange={formik.handleChange} value={formik.values.rangoedad} error={formik.errors.rangoedad} />
                <Form.Dropdown label="Tipo de piel" placeholder="Seleccione el tipo de piel" name="tipopiel" options={piel} selection onChange={(_, data) => formik.setFieldValue("tipopiel", data.value)} value={formik.values.tipopiel} error={formik.errors.tipopiel} />
                <Form.Dropdown label="Funciones que espera en el producto:" placeholder="Seleccione las opciones" fluid selection multiple options={funciones}
                 onChange={(_, data) => formik.setFieldValue("funciones", data.value)} value={formik.values.funciones || []} error={formik.errors.funciones} />
                 <Form.Input
                label="Otro(s)"
                name="otro"
                placeholder="Otra función que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOption}
                onBlur={onBlur}
                value={formik.values.otro}
                error={formik.errors.otro}
                />
                {// A que area del cuerpo va dirigida
                }
                <Form.Dropdown label="¿A qué área del cuerpo va dirigida?" placeholder="Seleccione las opciones" fluid selection multiple options={area}
                 onChange={(_, data) => formik.setFieldValue("area", data.value)} value={formik.values.area || []} error={formik.errors.area} />
                 <Form.Input
                label="Otro(s)"
                name="otroarea"
                placeholder="Otra área que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOptionarea}
                onBlur={onBlurarea}
                value={formik.values.otroarea}
                error={formik.errors.otroarea}
                />
                {// base del producto
                }
                <Form.Dropdown label="Base del producto" placeholder="Seleccione la base" fluid selection options={base}
                 onChange={(_, data) => formik.setFieldValue("base", data.value)} value={formik.values.base || []} error={formik.errors.base} />
                 <Form.Input
                label="Otro(s)"
                name="otrobase"
                placeholder="Otra base que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOptionbase}
                onBlur={onBlurbase}
                value={formik.values.otrobase}
                error={formik.errors.otrobase}
                />

                <Form.TextArea label='Ingredientes/Activos' name='activos' onChange={formik.handleChange} value={formik.values.activos} error={formik.errors.activos} />
                <Form.Checkbox label='Fragancia' name='fragancia' onChange={(_, data) => formik.setFieldValue("fragancia", data.checked)} 
                checked={formik.values.fragancia} error={formik.errors.fragancia} className='custom-checkbox' />
                <Form.Input label="Presentación del producto (ml)" name="presentacion" placeholder="0" onChange={(e) => {formik.setFieldValue('presentacion', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.presentacion} error={formik.errors.presentacion} />
                
                <a target='blank' href='https://omicron1ab-my.sharepoint.com/:x:/g/personal/fernanda_gomez_o-lab_mx/EaNzYIMkB0JFkfeDf2ESb6QBvyTrr3FjHuRiUxuOi1QW8Q?e=agFtPu&wdOrigin=TEAMS-MAGLEV.p2p_ns.rwc&wdExp=TEAMS-TREATMENT&wdhostclicktime=1732632119525&web=1'>
                    <Header 
                 className='center' color='green'>Bench Lanzamientos</Header>
                 </a>
                {// envase del producto
                }
                <Form.Dropdown label="Tipo de Envase" placeholder="Seleccione el envase" fluid selection options={envase}
                 onChange={(_, data) => formik.setFieldValue("envase", data.value)} value={formik.values.envase || []} error={formik.errors.envase} />
                 <Form.Input
                label="Otro(s)"
                name="otroenvase"
                placeholder="Otro envase que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOptionenvase}
                onBlur={onBlurenvase}
                value={formik.values.otroenvase}
                error={formik.errors.otroenvase}
                />
                <Form.Input label="Costo objetivo" name="objetivo" placeholder="0" onChange={(e) => {formik.setFieldValue('objetivo', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.objetivo} error={formik.errors.objetivo} />
               </Container>
               <br></br>
            <Container>
                <Form.Button type='submit' primary fluid loading={formik.isSubmitting} >
                    Enviar Cotización
                </Form.Button>
            </Container>
        </Form>
    )
}
