import * as Yup from "yup";

export function initialValues(reve) {
    return {
        presupuesto: reve?.presupuesto || '',
        preciounitario: reve?.preciounitario || '',
        codigo: reve?.codigo || ''
    }
}

export function validationSchema() {
    return Yup.object({
            presupuesto: Yup.number().required(true),
        });
}

export function validationSchema2() {
    return Yup.object({
            preciounitario: Yup.number().required(true)
        });
}

export function validationSchema3() {
    return Yup.object({
            codigo: Yup.string().required(true)
        });
}