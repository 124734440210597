import React from 'react'
import { Container, TableRow, TableBody, TableHeader, TableHeaderCell, Table, TableCell} from 'semantic-ui-react';

export function OpeView(props) {
  const { Reve } = props;

  return (
    <div className='cotizacion-principal'>
     
      <Container className='cotizacion-principal_info'>
              <p>Asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesornom}</span></p>
              <p>Correo asesor: <span className='cotizacion-principal_info-dxp'>{Reve.asesor}</span></p>
              <br></br>
              {Reve.nombreoficial && <p>Nombre del producto: <span className='cotizacion-principal_info-nombre'>{Reve.nombreoficial}</span></p>}
              <p>Nombre del producto:<span className='cotizacion-principal_info-dxp'>{Reve.nombre}</span></p>
              <p>Familia del producto:<span className='cotizacion-principal_info-dxp'>{Reve.familia}</span></p>
              <p>Activos: <span className='cotizacion-principal_info-dxp'>{Reve.activos}</span></p>
              <p>Base: <span className='cotizacion-principal_info-base'>{Reve.base}</span></p>
              <p>Presentación: <span className='cotizacion-principal_info-activos'>{Reve.presentacion}</span></p>
              <p>Envase: <span className='cotizacion-principal_info-activos'>{Reve.envase}</span></p>
              <p>Funciones: <span className='cotizacion-principal_info-dxp'>{Reve.funciones}</span></p>
              <p>Área: <span className='cotizacion-principal_info-dxp'>{Reve.area}</span></p>
              <p>Fragancia: <span className='cotizacion-principal_info-dxp'>{Reve.fragancia ? 'Sí' : 'No'}</span></p>
              {(Reve.sIyD && Reve.caducidad) && <p>Caducidad: <span className='cotizacion-principal_info-nombre'>{Reve.caducidad}</span></p>}
              <br></br>
              <p>Genero del paciente: <span className='cotizacion-principal_info-base'>{Reve.genero}</span></p>
              <p>Rango de edad: <span className='cotizacion-principal_info-dxp'>{Reve.rangoedad}</span></p>
              <p>Tipo de piel: <span className='cotizacion-principal_info-dxp'>{Reve.tipopiel}</span></p>
              <br></br>
              <p>Precio Objetivo: <span className='cotizacion-principal_info-activos'>{Reve.objetivo}</span></p>
              {Reve.presupuesto && <p>Presupuesto por KG de InyDe: <span className='cotizacion-principal_info-nombre'>{Reve.presupuesto}</span></p>}
              {(Reve.sIyD && Reve.costologrado) && <p>Costo por KG Logrado: <span className='cotizacion-principal_info-nombre'>{Reve.costologrado}</span></p>}
              {Reve.preciounitario && <p>Precio Unitario: <span className='cotizacion-principal_info-nombre'>{Reve.preciounitario}</span></p>}
              {Reve.codigo && <p>Codigo Formula Reve: <span className='cotizacion-principal_info-nombre'>{Reve.codigo}</span></p>}
              {Reve.cancelmotivo && <p>Motivo de cancelación: <span className='cotizacion-principal_info-nombre'>{Reve.cancelmotivo}</span></p>}
        </Container>
              
    </div>

  )
}
