import React, {useState, useEffect} from 'react'
import { Form, Header,  Container, TableRow, TableBody, TableHeader, TableHeaderCell, Table, TableCell} from 'semantic-ui-react'
import { useFormik } from 'formik'
import { initialValues, validationSchema } from './InydeForm.form'
import { Reve } from '../../../../../api'
import { useAuth } from '../../../../../hooks'
import ConfirmWithReason from './ConfirmWiithReason'
import  {opcionesBaseAreas, opcionesBaseBase, opcionesBaseEnvase, opcionesBaseFunciones, piel, generos, familia} from './constants'
import "./InydeForm.scss";

const reveController = new Reve();

export function InydeReveForm(props) {
    const { onClose, onReload, Reve } = props;
    const { accessToken } = useAuth();
    const { user: { email, firstname, lastname } } = useAuth();
    const nombreCompleto = `${firstname} ${lastname}`;

    const [funciones, setFunciones] = useState(opcionesBaseFunciones);
    const [area, setArea] = useState(opcionesBaseAreas);
    const [base, setBase] = useState(opcionesBaseBase);
    const [envase, setEnvase] = useState(opcionesBaseEnvase);
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);
    
    const formik = useFormik({
        initialValues: initialValues(Reve),
        validationSchema: validationSchema(),
        validateOnChange: false,
        onSubmit: async (formValue) => {
            console.log("entro al submit");
            try {
                console.log("entro al try");
                const funcionesSeleccionados = formValue.funciones;
                const funcionesTexto = funcionesSeleccionados.join(', ');
                const areaSeleccionados = formValue.area;
                const areaTexto = areaSeleccionados.join(', ');
                const data = {
                    base: formValue.base,
                    funciones: funcionesTexto ? funcionesTexto : [],
                    area: areaTexto ? areaTexto : [],
                    activos: formValue.activos,
                    fragancia: formValue.fragancia ? formValue.fragancia : false,
                    presentacion: formValue.presentacion,
                    envase: formValue.envase,
                    excel: formValue.excel,
                    costologrado: formValue.costologrado,
                    rangoedad: formValue.rangoedad,
                    genero: formValue.genero,
                    caducidad: formValue.caducidad,
                };
                
                if (Reve) {
                    await reveController.ReveInyde(accessToken, Reve._id, data);
                } else {
                   console.log("Esto no deberia de pasar")
                }
                onClose();
                onReload();
            } catch (error) {
                console.error(error);
            }
        }
    });
    
    useEffect(() => {
        if (Reve?.funciones) {
            const valoresUnicos = [
                ...new Set([
                    ...opcionesBaseFunciones.map((opt) => opt.value),
                    ...Reve.funciones.split(", ").filter(Boolean),
                ]),
            ];
            const opcionesCombinadas = valoresUnicos.map((valor) => ({
                key: valor,
                text: valor,
                value: valor,
            }));
            setFunciones(opcionesCombinadas);
        }
        if (Reve?.area) {
            const valoresUnicos = [
                ...new Set([
                    ...opcionesBaseAreas.map((opt) => opt.value),
                    ...Reve.area.split(", ").filter(Boolean),
                ]),
            ];
            const opcionesCombinadas = valoresUnicos.map((valor) => ({
                key: valor,
                text: valor,
                value: valor,
            }));
            setArea(opcionesCombinadas);
        }
        if (Reve?.base) {
            const valoresUnicos = [
                ...new Set([
                    ...opcionesBaseBase.map((opt) => opt.value),
                    ...Reve.base.split(", ").filter(Boolean),
                ]),
            ];
            const opcionesCombinadas = valoresUnicos.map((valor) => ({
                key: valor,
                text: valor,
                value: valor,
            }));
            setBase(opcionesCombinadas);
        }
        if (Reve?.envase) {
            const valoresUnicos = [
                ...new Set([
                    ...opcionesBaseEnvase.map((opt) => opt.value),
                    ...Reve.envase.split(", ").filter(Boolean),
                ]),
            ];
            const opcionesCombinadas = valoresUnicos.map((valor) => ({
                key: valor,
                text: valor,
                value: valor,
            }));
            setEnvase(opcionesCombinadas);
        }
    }, [Reve]);


    const openDesactivateActivateConfirm = () => {
        setConfirmMessage(`Cancelar Formula Reve ${Reve.folio}`);
        onOpenCloseConfirm();
      }

    const handleCancel = async (reason) => {
    try {
        await reveController.CancelReveInyde(accessToken, Reve._id, reason)
        onClose();
        onReload();
        onOpenCloseConfirm();
    } catch (error) {
        console.error(error)
    }
    }



    const handleAddCustomOption = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otro?.trim();
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!funciones.find((item) => item.value === newOption)) {
                    const updatedFunciones = [...funciones, { key: newOption, text: newOption, value: newOption }];
                    setFunciones(updatedFunciones);
                }
                // Asegurar que `formik.values.funciones` es un array válido.
                const currentFunciones = formik.values.funciones || [];
                formik.setFieldValue("funciones", [...currentFunciones, newOption]);
                formik.setFieldValue("otro", ""); // Resetear el input.
            }
        }
    };
    const handleAddCustomOptionarea = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otroarea?.trim();
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!area.find((item) => item.value === newOption)) {
                    const updatedAreas = [...area, { key: newOption, text: newOption, value: newOption }];
                    setArea(updatedAreas);
                }
                // Asegurar que `formik.values.funciones` es un array válido.
                const currentAreas = formik.values.area || [];
                formik.setFieldValue("area", [...currentAreas, newOption]);
                formik.setFieldValue("otroarea", ""); // Resetear el input.
            }
        }
    };
    const handleAddCustomOptionbase = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otrobase?.trim(); // Validar si tiene valor antes de trim().
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!base.find((item) => item.value === newOption)) {
                    const updatedBases = [...base, { key: newOption, text: newOption, value: newOption }];
                    setBase(updatedBases); // Actualizar las opciones del dropdown.
                }
                // Establecer el nuevo valor como el valor seleccionado del dropdown.
                formik.setFieldValue("base", newOption);
                formik.setFieldValue("otrobase", ""); // Resetear el input.
            }
        }
    };
    const handleAddCustomOptionenvase = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Evitar el envío del formulario en móviles.
            const newOption = formik.values.otroenvase?.trim(); // Validar si tiene valor antes de trim().
            if (newOption) {
                // Verificar si la opción ya existe en las opciones.
                if (!envase.find((item) => item.value === newOption)) {
                    const updatedEnvase = [...envase, { key: newOption, text: newOption, value: newOption }];
                    setEnvase(updatedEnvase); // Actualizar las opciones del dropdown.
                }
                // Establecer el nuevo valor como el valor seleccionado del dropdown.
                formik.setFieldValue("envase", newOption);
                formik.setFieldValue("otroenvase", ""); // Resetear el input.
            }
        }
    };
    
    const onBlur = (e) => {
        const newOption = formik.values.otro?.trim();
        if (newOption) {
            if (!funciones.find((item) => item.value === newOption)) {
                const updatedFunciones = [...funciones, { key: newOption, text: newOption, value: newOption }];
                setFunciones(updatedFunciones);
            }
            const currentFunciones = formik.values.funciones || [];
            formik.setFieldValue("funciones", [...currentFunciones, newOption]);
            formik.setFieldValue("otro", ""); // Resetear el input.
        }
    }
    const onBlurarea = (e) => {
        const newOption = formik.values.otroarea?.trim();
        if (newOption) {
            if (!area.find((item) => item.value === newOption)) {
                const updatedAreas = [...area, { key: newOption, text: newOption, value: newOption }];
                setArea(updatedAreas);
            }
            const currentAreas = formik.values.areas || [];
            formik.setFieldValue("areas", [...currentAreas, newOption]);
            formik.setFieldValue("otroarea", ""); // Resetear el input.
        }
    }
    const onBlurbase = (e) => {
        const newOption = formik.values.otrobase?.trim(); // Validar si tiene valor antes de trim().
        if (newOption) {
            // Verificar si la opción ya existe en las opciones.
            if (!base.find((item) => item.value === newOption)) {
                const updatedBases = [...base, { key: newOption, text: newOption, value: newOption }];
                setBase(updatedBases); // Actualizar las opciones del dropdown.
            }
            // Establecer el nuevo valor como el valor seleccionado del dropdown.
            formik.setFieldValue("base", newOption);
            formik.setFieldValue("otrobase", ""); // Resetear el input.
        }
    }

    const onBlurenvase = (e) => {
        const newOption = formik.values.otroenvase?.trim(); // Validar si tiene valor antes de trim().
        if (newOption) {
            // Verificar si la opción ya existe en las opciones.
            if (!envase.find((item) => item.value === newOption)) {
                const updatedEnvases = [...envase, { key: newOption, text: newOption, value: newOption }];
                setEnvase(updatedEnvases); // Actualizar las opciones del dropdown.
            }
            // Establecer el nuevo valor como el valor seleccionado del dropdown.
            formik.setFieldValue("envase", newOption);
            formik.setFieldValue("otroenvase", ""); // Resetear el input.
        }
    }
   
    const date = new Date(Reve.created_at);
    const createdate = date.toLocaleDateString();

    const handleSave = async () => {
        try {
            console.log("entro al try")
            const funcionesSeleccionados = formik.values.funciones;
            const funcionesTexto = funcionesSeleccionados.join(', ');
            const areaSeleccionados = formik.values.area;
            const areaTexto = areaSeleccionados.join(', ');
            const data = {
                base: formik.values.base,
                funciones: funcionesTexto ? funcionesTexto : [],
                area: areaTexto ? areaTexto : [],
                activos: formik.values.activos,
                genero: formik.values.genero,
                nombre: formik.values.nombre,
                rangoedad: formik.values.rangoedad,
                tipopiel: formik.values.tipopiel,
                fragancia: formik.values.fragancia ? formik.values.fragancia : false,
                presentacion: formik.values.presentacion,
                envase: formik.values.envase,
                objetivo: formik.values.objetivo,
                excel: formik.values.excel,
                costologrado: formik.values.costologrado,
                caducidad: formik.values.caducidad,
            }
    
            await reveController.saveReveOpe(accessToken, Reve._id, data)
            onClose();
            onReload();
            } catch (error) {
            console.error(error);
        }
    }

    return (
        <Form onSubmit={formik.handleSubmit} className='form'>
            <Container className='form-cotizacion__primer'>
                    <h3>{Reve.cancelmotivo && <p>Motivo de cancelación: <span>{Reve.cancelmotivo}</span></p>}</h3>
                    <h3>{Reve.backmotivo && <p>Motivo de regreso: <span>{Reve.backmotivo}</span></p>}</h3>
                <Form.Group className="form-group">
                    <p>Asesor: <span>{Reve.asesornom}</span></p>
                </Form.Group>
                <Form.Input readOnly label="Nombre del producto" name="nombre" placeholder="Nombre del producto" onChange={formik.handleChange} value={formik.values.nombre} error={formik.errors.nombre} />
                <Form.Group className="form-group">
                    <Form.Input readOnly label="Familia" name="familia" placeholder="" onChange={formik.handleChange} value={formik.values.familia} error={formik.errors.familia} />
                    <Form.Input readOnly label="Rango de edad" name="rangoedad" placeholder="Rango de edad" onChange={formik.handleChange} value={formik.values.rangoedad} error={formik.errors.rangoedad} />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Input readOnly label="Género" name="genero" placeholder="" onChange={formik.handleChange} value={formik.values.genero} error={formik.errors.genero} />
                    <Form.Input readOnly label="Tipo de piel" name="tipopiel" placeholder="" onChange={formik.handleChange} value={formik.values.tipopiel} error={formik.errors.tipopiel} />
                </Form.Group>
                <Form.Dropdown label="Funciones que espera en el producto:" placeholder="Seleccione las opciones" fluid selection multiple options={funciones}
                 onChange={(_, data) => formik.setFieldValue("funciones", data.value)} value={formik.values.funciones || []} error={formik.errors.funciones} />
                 <Form.Input
                label="Otro(s)"
                name="otro"
                placeholder="Otra función que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOption}
                onBlur={onBlur}
                value={formik.values.otro}
                error={formik.errors.otro}
                />
                {// A que area del cuerpo va dirigida
                }
                <Form.Dropdown label="¿A qué área del cuerpo va dirigida?" placeholder="Seleccione las opciones" fluid selection multiple options={area}
                 onChange={(_, data) => formik.setFieldValue("area", data.value)} value={formik.values.area || []} error={formik.errors.area} />
                 <Form.Input
                label="Otro(s)"
                name="otroarea"
                placeholder="Otra área que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOptionarea}
                onBlur={onBlurarea}
                value={formik.values.otroarea}
                error={formik.errors.otroarea}
                />
                {// base del producto
                }
                <Form.Dropdown label="Base del producto" placeholder="Seleccione la base" fluid selection options={base}
                 onChange={(_, data) => formik.setFieldValue("base", data.value)} value={formik.values.base || []} error={formik.errors.base} />
                 <Form.Input
                label="Otro(s)"
                name="otrobase"
                placeholder="Otra base que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOptionbase}
                onBlur={onBlurbase}
                value={formik.values.otrobase}
                error={formik.errors.otrobase}
                />
                <Form.TextArea label='Ingredientes/Activos' name='activos' onChange={formik.handleChange} value={formik.values.activos} error={formik.errors.activos} />
                <Form.Checkbox label='Fragancia' name='fragancia' onChange={(_, data) => formik.setFieldValue("fragancia", data.checked)} 
                checked={formik.values.fragancia} error={formik.errors.fragancia} className='custom-checkbox' />
                <Form.Dropdown label="Tipo de Envase" placeholder="Seleccione el envase" fluid selection options={envase}
                 onChange={(_, data) => formik.setFieldValue("envase", data.value)} value={formik.values.envase || []} error={formik.errors.envase} />
                 <Form.Input
                label="Otro(s)"
                name="otroenvase"
                placeholder="Otro envase que no está en la lista."
                onChange={formik.handleChange}
                onKeyDown={handleAddCustomOptionenvase}
                onBlur={onBlurenvase}
                value={formik.values.otroenvase}
                error={formik.errors.otroenvase}
                />
                <Form.Input  readOnly label="Presentación del producto (ml)" name="presentacion" placeholder="Presentacion del producto" onChange={formik.handleChange} value={formik.values.presentacion} error={formik.errors.presentacion} />
                <Container className='precio_unitario'>
                <Table className='table-precio' celled>
                    <TableHeader>
                        <TableRow>
                        
                        <TableHeaderCell>Presupuesto por KG</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
            
                        <TableCell>{Reve.presupuesto}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </Container>
                <br></br>
                <Container className='precio_unitario'>
                    <Table className='table-precio' celled>
                        <TableHeader>
                            <TableRow>
                   
                            <TableHeaderCell>Costo por KG logrado</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                       
                            <TableCell><Form.Input name='costologrado' placeholder='0' onChange={(e) => {formik.setFieldValue('costologrado', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.costologrado} error={formik.errors.costologrado} /></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Container>
                <br></br>
                <Form.Input label="Caducidad (meses)" name="caducidad" placeholder="0" onChange={(e) => {formik.setFieldValue('caducidad', e.target.value.replace(/[^0-9]/g, ''))}} value={formik.values.caducidad} error={formik.errors.caducidad} />
                <br></br>
               </Container>
               <a target='blank' href='https://omicron1ab-my.sharepoint.com/:x:/r/personal/dev1_o-lab_mx/_layouts/15/Doc.aspx?sourcedoc=%7B1F78BEC1-A001-4E31-8CC3-0B6079DC5540%7D&file=Procedimiento%20Producto%20Nuevo.xlsx&action=default&mobileredirect=true'>
                    <Header
                 className='center' color='green'>Excel de datos</Header>
                 </a>
                 <Form.Checkbox label='Excel' name='excel' onChange={(_, data) => formik.setFieldValue("excel", data.checked)}
                checked={formik.values.excel} error={formik.errors.excel} className='custom-checkbox' />
               <br></br>
            <Container>
                <Form.Button type='button' primary fluid onClick={handleSave} loading={formik.isSubmitting}>
                    Guardar
                </Form.Button>
                <Form.Button type='submit' primary fluid loading={formik.isSubmitting} >
                    Enviar Cotización
                </Form.Button>
                <Form.Button type='button' color='red' fluid onClick={openDesactivateActivateConfirm}>
                    Cancelar
                </Form.Button>
                <ConfirmWithReason open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={handleCancel} content={confirmMessage} size='mini'></ConfirmWithReason>
            </Container>
        </Form>
    )
}
